import { dateFnsLocale, listTeaserDateFormat, timeZone } from '@hubcms/brand';
import type { ListTeaserProps as ChameleonListTeaserProps } from '../../domain/chameleon/list-teaser-props';
import { getDateTimeFormat } from '../../utils/getDateTimeFormat';
import { hasAlternativePremiumLogo } from '../../utils/hasAlternativePremiumLogo';
import type { ListTeaserProps } from './types';
import { getTeaserLabelProp } from '../../utils/getTeaserLabelProp';

export function getChameleonProps(props: ListTeaserProps): ChameleonListTeaserProps {
  const teaserLabelProp = getTeaserLabelProp(props.contentType, 'ListTeaser');

  const chameleonProps: ChameleonListTeaserProps = {
    dateTime: (props.hasDateTime && props.dateTime) || '',
    dateTimeOptions: {
      format: listTeaserDateFormat || getDateTimeFormat(props.isDateToday),
      locale: dateFnsLocale,
      timeZone,
    },
    external: props.isExternal,
    link: props.link,
    prefix: teaserLabelProp === 'prefix' ? props.label : props.prefix,
    premium: props.isPremium || hasAlternativePremiumLogo(props.theme),
    title: props.title,
    titleTagName: `h${props.headerLevel}` as ChameleonListTeaserProps['titleTagName'],
  };

  return chameleonProps;
}
